<template>
  <v-card id="customer-form">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-form
      ref="AdminForm"
      v-model="valid"
      lazy-validation
    >
      <v-card-title>Admin Form</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="AdminForm.username"
          label="Username"
          required
          :disabled="crmIdDisabled"
          :rules="name_rules"
          :counter="20"
        />

        <v-text-field
          v-model="AdminForm._name"
          label="Name"
          required
          :disabled="crmIdDisabled"
          :rules="name_rules"
          :counter="255"
        />

        <v-text-field
          v-model="AdminForm.surname"
          label="Surname"
          required
          :disabled="crmIdDisabled"
          :rules="name_rules"
          :counter="255"
        />

        <v-text-field
          v-model="AdminForm.email"
          label="Email"
          required
          :disabled="crmIdDisabled"
          :rules="email_rules"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          class="mr-4"
          @click.prevent="clear"
        >
          Clear
        </v-btn>
        <v-spacer />
        <v-btn
          dark
          color="color_green"
          class="mr-4"
          :disabled="checkForm()"
          @click="submit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {
  required,
  manageLenghtError,
  getRegExp,
  email_regexp
} from '@/formRules.js';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      overlay: false,
      valid: true,
      AdminForm: {
        username: '',
        _name: '',
        surname: '',
        email: ''
      },
      crmIdDisabled: false,
      name_rules: [
        ...required,
        ...getRegExp('space_not_allowed'),
        ...manageLenghtError(255)
      ],
      email_rules: [...required, ...email_regexp]
    };
  },

  computed: {
    ...mapState(['admin'])
  },

  methods: {
    ...mapActions(['storeNewAdmin', 'storeCustomerCRMID', 'storeToastMessage']),
    checkForm() {
      return !(this.valid &&
        this.AdminForm.username !== '' &&
        this.AdminForm._name !== '' &&
        this.AdminForm.surname !== '' &&
        this.AdminForm.email !== '');
    },
    async submit() {
      this.overlay = true;

      if (!this.checkForm() && this.valid) {
        await this.storeNewAdmin(this.AdminForm);
        await this.storeCustomerCRMID(this.AdminForm);
        this.crmIdDisabled = true;
        this.$emit('closeDialog');
      } else {
        this.storeToastMessage({
          text: 'Please set required fields',
          type: 'warning'
        });
      }

      this.overlay = false;
    },
    clear() {
      this.AdminForm.username = '';
      this.AdminForm._name = '';
      this.AdminForm.surname = '';
      this.AdminForm.email = '';
    }
  }
};
</script>
